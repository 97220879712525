<template>
  <div class="Statisticslist">
    <div class="tit">
        <el-select class="box two" clearable v-model="cardname" placeholder="请选择案件类型">
          <el-option
            v-for="item in cardlist"
            :key="item.id"
            :label="item.cardname"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class="box two" clearable v-model="visitstatusname" placeholder="请选择审核状态">
          <el-option
            v-for="item in visitstatuslist"
            :key="item.id"
            :label="item.visitstatusname"
            :value="item.id">
          </el-option>
        </el-select>
        <el-input class="box four" placeholder="请输入手机号查询" v-model="phone" clearable> </el-input>
        <el-button class="box submit" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
    </div>
    <div class="content">
      <el-table :data="tableData" style="width: 100%" ref="multipleTable" tooltip-effect="dark" :header-cell-style="{textAlign:'center'}"
          :cell-style="{textAlign:'center'}">
        <el-table-column label="序号" type="index"> </el-table-column>
        <el-table-column label="信访内容" :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.visitcontext }}</p>
            </template>
        </el-table-column>

        <el-table-column label="案件类型"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.cardname }}</p>
            </template>
        </el-table-column>

        <el-table-column label="审核状态"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.visitstatusname }}</p>
            </template>
        </el-table-column>
        <el-table-column label="预约人姓名"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.username }}</p>
            </template>
        </el-table-column>
        <el-table-column label="预约人手机号码"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.phone }}</p>
            </template>
        </el-table-column>
        <el-table-column label="审核人姓名"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.vusername }}</p>
            </template>
        </el-table-column>
        <el-table-column label="审核人手机号码"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.checkmanphone }}</p>
            </template>
        </el-table-column>
        <el-table-column label="开始时间"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.visitstarttime }}</p>
            </template>
        </el-table-column>
        <el-table-column label="结束时间"
        :show-overflow-tooltip="true">
            <template slot-scope="scope">
            <p>{{ scope.row.visitendtime }}</p>
            </template>
        </el-table-column>
        </el-table>
        <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="page"
            :pager-count="9"
            layout="total, prev, pager, next, jumper"
            :total="total"
            hide-on-single-page
        >
        </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import{getchartList} from '@/utils/api'
export default {
    data(){
        return{
            prolist:[],
            procuratorate:'',
            cardlist:[],
            cardname:'',
            visitstatuslist:[],
            visitstatusname:'',
            vuserlist:[],
            vusername:'',
            phone:'',
            time:'',
            tableData: [], //列表
            total: 0, //总条数
            page: 10, //页数
            currentPage: 1, //当前页数
        }
    },
    methods: {
      // 获取接访列表.
      getvisitlist(){
        let data = {
          // pid:this.procuratorate,   //检察院id
          did:this.cardname,   //案件类型id
          vid:this.visitstatusname,   //审核状态id
          // userId:this.vusername,   //用户
          phone:this.phone,   //手机号
          // startsTime:this.time[0],   //开始时间
          // endTime:this.time[1],   //结束时间
          limit:this.currentPage,  //第几页
          page:this.page,  //每页数量
        }
        getchartList(data).then(res =>{
          console.log('查询列表',res);
          res.data.data.data.map(item =>{
            if(item.visitstarttime !== undefined){
              item.visitstarttime = item.visitstarttime.replace("T"," ")
            }
            if(item.visitendtime !== undefined){
              item.visitendtime = item.visitendtime.replace("T"," ")
            }
            return item
          })
          this.tableData = res.data.data.data
          this.prolist = res.data.data.userId
          this.cardlist = res.data.data.dict
          this.visitstatuslist = res.data.data.static
          this.vuserlist = res.data.data.user
          if(res.data.data.total.length !== 0){
            this.total = res.data.data.total[0].total
          }
        })
      },
      search(){
        // console.log(this.cardname);
        var startTime = ''
        var endTime = ''
        if (this.time !== '' && this.time !== null) {
          // console.log(this.time,'进来了')
          endTime = this.time[1] ;
          startTime = this.time[0];
          endTime=endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate();
          startTime=startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate();
        }else{
          endTime = '';
          startTime = '';
        }
        // console.log(this.time);
        this.getvisitlist()
      },
      handleCurrentChange(val) {
          // console.log(`当前页: ${val}`);
          this.currentPage = val
          // console.log(this.currentPage4);
          this.getvisitlist()
      },
    },
    mounted() {
      this.getvisitlist()
    },
}
</script>

<style lang="less" scoped>
.Statisticslist{
  width: auto;
  min-height: 95%;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  .tit{
    display: flex;
    .box{
      margin-right: 10px;
      // height: 36px;
    }
  }
  .content{
    width: auto;
    margin-top: 20px;
    
  }
}
.block{
  margin-top: 20px;
}
.el-pagination {
    text-align: center; 
}
.el-input{
  width: 250px;
}
// ::v-deep .el-button{
//   margin-left: 10px;
// }
</style>